import React, { useState } from 'react';
import { MdMathEditor } from '~/components';

export const Teams = () => {
  const [text, setText] = useState('Juki');
  return (
    <div>
      <div>Teams</div>
      <MdMathEditor
        value={text}
        onChange={(value) => setText(value)}
      />
    </div>
  );
}
