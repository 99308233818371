import { ErrorResponse, STATUS_ABORTED, SuccessResponse } from '~/services';
import { LoaderAction, Status } from '~/types';
import { NotificationType } from '@bit/juki.commons.types';
import { flagsState } from '~/state/flags';

export const stateRequestWrapper = async <T>(request: () => Promise<ErrorResponse | SuccessResponse<T>>, onSuccess: (result: SuccessResponse<T>) => void, setLoader?: LoaderAction, onFailure?: (result: ErrorResponse) => void) => {
  const timeStamp = new Date().getTime();
  setLoader?.([timeStamp, Status.LOADING]);
  const result = await request();
  if (result) {
    if (result.success) {
      setLoader?.(prevState => prevState[0] === timeStamp ? [timeStamp, Status.SUCCESS] : prevState);
      onSuccess(result);
    } else {
      let setError = false;
      if (setLoader) {
        setLoader((prevState: any) => {
          /* TODO:
           Warning: Render methods should be a pure function of props and state; triggering nested component updates from render is not allowed. If necessary, trigger nested updates in componentDidUpdate.
           * */
          if (!setError && prevState[0] === timeStamp) {
            setError = true;
            if (result.status !== STATUS_ABORTED) {
              return [timeStamp, Status.ERROR];
            } else {
              return [timeStamp, Status.NONE];
            }
          }
          return prevState;
        });
      }
      if (setError) {
        flagsState.merge({
          lastNotification: {
            type: NotificationType.ERROR,
            title: `Ups ${result.status}`,
            description: result.message,
          },
        });
        onFailure?.(result);
      }
    }
  }
};
