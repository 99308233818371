import { createState, useState as usesState } from '@hookstate/core';
import { DEFAULT_FLAGS_STATE, ERROR, LOADING, SUCCESS, USER_GUEST } from '~/config/constants';
import { LoaderAction, NotificationType, UserState } from '~/types';
import { apiLogInUser, apiLogoutUser, apiMe, apiSignUpUser } from '~/services';
import { flagsState } from '~/state/flags';
import { stateRequestWrapper } from '~/state/utils';
import { useMemo } from 'react';

export const userState = createState({ ...USER_GUEST });

export const cleanState = () => {
  userState.set({ ...USER_GUEST });
  flagsState.set({ ...DEFAULT_FLAGS_STATE });
}

export const useUserState = () => {
  const state = usesState(userState);
  return {
    get nickname() {
      return state.nickname.get();
    },
    get imageUrl() {
      return state.imageUrl.get();
    },
    get settings() {
      return state.settings.get();
    },
    get isLogged() {
      return state.isLogged.get();
    },
  }
}
export const useUserDispatch = () => {
  const state = usesState(userState);
  const updateUser = ({ handles, settings, ...rest }: UserState) => {
    if (handles) {
      state.handles.merge(handles);
    }
    if (settings) {
      state.settings.merge(settings);
    }
    state.merge(rest);
  }
  return useMemo(() => ({
    signIn: (nickname: string, password: string, setLoader: LoaderAction) => () => stateRequestWrapper(
      apiLogInUser({ nickname, password }),
      (result) => {
        updateUser({ ...result.data, isLogged: true });
      },
      setLoader,
    ),
    signUp: (body: { givenName: string, familyName: string, nickname: string, email: string, password: string }, setLoader: LoaderAction) => () => stateRequestWrapper(
      apiSignUpUser(body),
      (result) => {
        updateUser({ ...result.data, isLogged: true });
      },
      setLoader,
    ),
    ping: () => async () => {
      const response = await apiMe();
      if (response.success) {
        updateUser({ ...response.data, isLogged: true });
      }
    },
    logout: (setLoader: LoaderAction) => async () => {
      setLoader([1, LOADING]);
      const result = await apiLogoutUser();
      if (result.success) {
        setLoader([1, SUCCESS]);
        flagsState.merge({
          lastNotification: {
            type: NotificationType.INFO,
            title: 'See you',
            description: '',
          },
        });
      } else {
        setLoader([1, ERROR]);
        flagsState.merge({
          lastNotification: {
            type: NotificationType.INFO,
            title: 'Force log out',
            description: result.message,
          },
        });
      }
      cleanState();
    },
    // eslint-disable-next-line
  }), []);
}
