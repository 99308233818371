import React, { Suspense, useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Loader, LoaderIcon, NotFound } from '~/components';
import { classNames } from '~/helpers';
import { Language, ProfileSettingOptions, Theme } from '~/types';
import { _KEY } from '~/config/constants';
import { Home } from './components/Home';
import { CoursesList } from './components/courses/List';
import { CourseView } from './components/courses/View';
import { Tasks } from './components/Tasks';
import { Teams } from './components/Teams';
import { Schedule } from './components/Schedule';
import { InlineNavbar } from './components/InlineNavBar';
import { ROUTES } from './config/constants';
import { useFlagsState, useUserDispatch, useUserState } from '~/state';
import './i18n';

const App = () => {
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const { settings: { [ProfileSettingOptions.LANGUAGE]: lang = Language.ENGLISH, [ProfileSettingOptions.THEME]: theme = Theme.LIGHT } } = useUserState();
  const { ping } = useUserDispatch();
  const { i18n } = useTranslation();
  const { isSideBarOpen } = useFlagsState();
  useEffect(() => {
    ping()().then(() => setLoader(false));
  }, [ping]);
  useEffect(() => {
    i18n.changeLanguage(lang).then(_ => console.info(`Successfully configured language: ${lang}`));
    document.documentElement.className = '';
    document.documentElement.classList.add(`jk-theme-${theme}`);
  }, [i18n, lang, theme]);
  
  return (
    <Loader
      loading={loader}
      component={() => (
        <div className="app-viewport">
          <InlineNavbar />
          <div className={classNames('app-body', { 'sidebar-open': isSideBarOpen })}>
            <div className={classNames('app-main')}>
              <div
                className="app-content-main"
              >
                <Suspense fallback={<LoaderIcon />}>
                  <Switch>
                    <Route exact path={'/'} component={Home} />
                    <Route exact path={ROUTES.COURSES.COURSE(_KEY)} component={CourseView} />
                    <Route exact path={ROUTES.COURSES.LIST_PAGE()} component={CoursesList} />
                    <Route exact path={ROUTES.TASKS.DASHBOARD()} component={Tasks} />
                    <Route exact path={ROUTES.TEAMS.DASHBOARD()} component={Teams} />
                    <Route exact path={ROUTES.SCHEDULE.DASHBOARD()} component={Schedule} />
                    <Route
                      exact
                      path={'*'}
                      component={() => (
                        <NotFound
                          redirectAction={() => {
                            setTimeout(() => {
                              history.push('/');
                            }, 2000);
                          }}
                        />
                      )}
                    />
                  </Switch>
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default App;
