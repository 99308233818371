import { User } from '~/types';
import { authorizedRequest, ErrorResponse, JUKI_COUCH_API, POST, SuccessResponse } from '../server.config';
import { clean } from './index';

export const apiSignUpUser = (body: { givenName: string, familyName: string, nickname: string, email: string, password: string }) => async (): Promise<ErrorResponse | SuccessResponse<User>> => {
  return clean(await authorizedRequest(JUKI_COUCH_API.ACCOUNT.SIGNUP(), POST, JSON.stringify(body)));
};

export const apiMe = async (): Promise<ErrorResponse | SuccessResponse<User>> => {
  return clean(await authorizedRequest(JUKI_COUCH_API.ACCOUNT.ME()));
};

export const apiLogInUser = (body: { nickname: string, password: string }) => async (): Promise<ErrorResponse | SuccessResponse<User>> => {
  return clean(await authorizedRequest(JUKI_COUCH_API.ACCOUNT.LOG_IN(), POST, JSON.stringify(body)));
};

export const apiLogoutUser = async (): Promise<ErrorResponse | SuccessResponse<undefined>> => {
  return clean(await authorizedRequest(JUKI_COUCH_API.ACCOUNT.LOGOUT(), POST));
};
//
// export const apiUserUpdate = (body?: any) => async (): Promise<ErrorResponse | OkObjectResponse<AccountStateFetch>> => {
//   return clean(await authorizedRequest(JUKI_COUCH_API.ACCOUNT.UPDATE(), PUT, JSON.stringify(body)));
// };
//
// export const apiUserUpdateImageUser = (blob: Blob) => async (): Promise<ErrorResponse | OkObjectResponse<AccountStateFetch>> => {
//   const formData = new FormData();
//   formData.append('file', blob);
//
//   return clean(await authorizedFormDataRequest(JUKI_COUCH_API.ACCOUNT.UPDATE_IMAGE(), PUT, formData));
// };
//
// export const apiUserUpdatePasswordUser = (oldPassword: string, newPassword: string) => async (): Promise<ErrorResponse | OkObjectResponse<AccountStateFetch>> => {
//   return clean(await authorizedRequest(JUKI_COUCH_API.ACCOUNT.CHANGE_PASSWORD(), PUT, JSON.stringify({ oldPassword, newPassword })));
// };
