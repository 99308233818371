import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  es: {
    translation: {
      'Welcome to React': 'Welcome to React and react-i18next',
      'Competitive programmers home': 'El hogar para programadores competitivos',
      'LOGIN': 'INGRESAR',
      'SIGN UP': 'REGISTRAR',
      'my profile': 'mi perfil',
      'logout': 'salir',
      'Problems': 'Problemas',
      'Contests': 'Concursos',
      'Admin': 'Admin',
      'is designed to make people improve their programming skills.': 'está diseñado para que las personas mejoren sus habilidades de programación.',
      'News': 'Noticias',
      'Live': 'en vivo',
      'Enter': 'Ingresar',
      'View scoreboard': 'Tabla de posiciones',
      'more': 'más',
      'Create': 'Crear',
      'live': 'en vivo',
      'lives': 'en vivos',
      'upcoming': 'Futuro',
      'upcomings': 'Futuros',
      'past': 'Pasado',
      'pasts': 'Pasados',
      'problem name': 'nombre del problema',
      '% success rate': '% tasa de éxito',
      'tags': 'etiquetas',
      'registered': 'registrado',
      'registereds': 'registrados',
      'date': 'fecha',
      'contest name': 'nombre del concurso',
      'update information': 'actualizar',
      'change password': 'cambiar contraseña',
      'upload image': 'subir imagen',
      'choose file': 'elegir imagen',
      'first name': 'nombre(s)',
      'last name': 'apellido(s)',
      'nickname': 'apodo',
      'country': 'país',
      'city/state': 'ciudad/estado',
      'institution': 'institución',
      'e-mail address': 'correo electronico',
      'new password': 'nueva contraseña',
      'confirm new password': 'confirmar nueva contraseña',
      'put your password to update': 'escribe tu contraseña para actualizarla',
      'update your photo': 'actualiza tu foto',
      'personal profile': 'perfil personal',
      'settings': 'ajustes',
      'submissions': 'envíos',
      'verdict': 'veredicto',
      'language': 'lenguaje',
      'time used': 'tiempo',
      'memory used': 'memoria',
      'code': 'código',
      'stars in': 'empieza en',
      'ended ago': 'terminó hace',
      'ends ind': 'termina en',
      'years': 'años',
      'weeks': 'semanas',
      'days': 'días',
      'hours': 'horas',
      'minutes': 'minutos',
      'seconds': 'segundos',
      'Overview': 'General',
      'Problem': 'Problema',
      'Scoreboard': 'Marcador',
      'My submissions': 'Mis envíos',
      'Clarifications': 'Aclaraciones',
      'Status': 'Envíos',
      'Timing': 'Cronometraje',
      'Setup': 'Ajustes',
      'Archive': 'Archivar',
      'All contest': 'Concursos',
      'statement': 'enunciado',
      'editor': 'editor',
      'tests': 'casos',
      'ranking': 'posiciones',
      'statistics': 'estadísticas',
      'setup': 'ajustes',
      'about us': 'sobre nostros',
      'question': 'pregunta',
      'answer': 'respuesta',
      'New clarification': 'Nueva pregunta',
      'submit': 'enviar',
      'contestant': 'concursante',
      'user answer': 'juez',
      'index problem': 'Índice del problema',
      '__break1': 'Concursos',
      '__break2': '',
      'Submissions are responded to within': 'Las respuestas seran respondidas dentro de',
      'Scoreboard frozen after': 'El marcador se congelara en',
      'Penalty by incorrect answer': 'Penalidad por respuesta incorrecta',
      'Time for solve': 'Tiempo para resolver',
      'Clarifications available': 'Aclaraciones disponible',
      'Clarifications not available': 'Aclaraciones no disponibles',
      'Register': 'Registrarse',
      'To register, first login': 'Para registrarse, inicie sesión',
      'total of submissions': 'total de envios',
      'contestants': 'total de participantes',
      'print scoreboard': 'imprimir el marcador',
      'duration': 'duración'
    }
  },
  en: {
    translation: {
      '__break1': '',
      '__break2': ' Contests',
      'pasts': 'past',
      'upcomings': 'upcoming',
      'lives': 'live',
      'registereds': 'registered'
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
