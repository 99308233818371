import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';

import { LayoutMain, Loader, MdMathViewer } from '~/components';
import { classNames, getPlainText } from '~/helpers';

export const CourseView = () => {
  const { key } = useParams<{ key: string }>();
  const [text, setText] = useState('Juki!');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true)
    getPlainText('/int-prog-cpp.md').then((text) => {
      setText(text);
      setLoading(false);
    });
  }, [key]);
  if (key === 'int-prog-cpp') {
    console.log(text);
    return (
      <Loader
        loading={loading}
        component={() => (
          <LayoutMain className={classNames('page-courses')}>
            <div className="juki-card jk-shadow">
              <MdMathViewer
                source={text}
              />
            </div>
          </LayoutMain>
        )}
      />
    );
  }
  return <Redirect to="/404" />;
};
