import React, { ReactNode } from 'react';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';

import { classNames } from '~/helpers';

import './Layouts.scss';
import { useFlagsDispatch, useFlagsState } from '~/state';

export const LayoutSideBar = ({ className, children }: { className?: string, children: ReactNode }) => {
  const { isSideBarOpen } = useFlagsState();
  const { mergeFlags } = useFlagsDispatch();
  return (
    <div
      className={classNames('layout-side-bar', { [className + '']: !!className }, { open: isSideBarOpen }, { close: isSideBarOpen })}
    >
      <div className="content-layout-side-bar">
        {children}
      </div>
      <div onClick={mergeFlags({ isSideBarOpen: !isSideBarOpen })} className="button-action-open-close">
        {isSideBarOpen ? <RightCircleOutlined /> : <LeftCircleOutlined />}
      </div>
    </div>
  );
};

export const LayoutSideMain = ({ className, children }: { className?: string, children: ReactNode }) => {
  const { isSideBarOpen } = useFlagsState();
  return (
    <div
      className={classNames('layout-side-main', { [className + '']: !!className }, { 'side-bar-open': isSideBarOpen }, { 'side-bar-close': isSideBarOpen })}
    >
      <div className="content-layout-side-main">
        {children}
      </div>
    </div>
  );
};

export const LayoutTitle = ({ className, children }: { className?: string, children: ReactNode }) => {
  return (
    <div className={classNames('layout-title', { [className + '']: !!className })}>
      <div className="content-layout-title">
        {children}
      </div>
    </div>
  );
};

export const LayoutMain = ({ className, children }: { className?: string, children: ReactNode }) => {
  return (
    <div className={classNames('layout-main', { [className + '']: !!className })}>
      <div className="content-layout-main">
        {children}
      </div>
    </div>
  );
};
