import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationType } from '~/types';
import { ErrorNotification, InfoNotification, SuccessNotification, WarningNotification } from './Notifications';
import { useFlagsDispatch, useFlagsState } from '~/state';

export const NotificationTrigger = () => {
  
  const { lastNotification } = useFlagsState();
  const { mergeFlags } = useFlagsDispatch();
  const { t } = useTranslation();
  
  useEffect(() => {
    switch (lastNotification?.type) {
      case NotificationType.SUCCESS:
        SuccessNotification({
          title: t(lastNotification?.title || ''),
          description: t(lastNotification.description),
          duration: lastNotification?.duration,
        });
        break;
      case NotificationType.INFO:
        InfoNotification({
          title: t(lastNotification?.title || ''),
          description: t(lastNotification.description),
          duration: lastNotification?.duration,
        });
        break;
      case NotificationType.WARNING:
        WarningNotification({
          title: t(lastNotification?.title || ''),
          description: t(lastNotification.description),
          duration: lastNotification?.duration,
        });
        break;
      case NotificationType.ERROR:
        ErrorNotification({
          title: t(lastNotification?.title || ''),
          description: lastNotification.description,
          duration: lastNotification?.duration,
        });
    }
    if (lastNotification) {
      mergeFlags({ lastNotification: null })();
    }
  }, [lastNotification, mergeFlags, t]);
  
  return <></>;
};

export * from './Notifications';
