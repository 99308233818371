import React from 'react';

import { SignUpInput, SignUpModal } from '~/components';
import { LoaderAction } from '~/types';
import { useFlagsDispatch, useUserDispatch } from '~/state';

export const SignUp = () => {
  
  const { signUp } = useUserDispatch();
  const { mergeFlags } = useFlagsDispatch();
  
  const onSubmit = ({ givenName, familyName, nickname, email, password, checkbox }: SignUpInput, setLoading: LoaderAction) => {
    signUp({ givenName, familyName, nickname, email, password }, setLoading)();
  };
  
  return (
    <SignUpModal
      onCancel={mergeFlags({ isSignUpModalOpen: false })}
      onSubmit={onSubmit}
      imageSource="https://static.oscargauss.com/images/juki-laptop.svg"
    />
  );
};
