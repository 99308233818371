import React from 'react';

import { Loader, LayoutMain } from '~/components';
import { classNames } from '~/helpers';
import { CourseCard } from './CourseCard';

import './styles.scss';

export const CoursesList = () => {
  const courses: any[] = [
    {
      key: 'int-prog-cpp',
      title: 'Introducción a la programación con C++',
      summary: 'En este curso aprenderas los fundamentos de la programación con ayuda del lenguaje de programación C++',
      lessons: 7,
      hours: 18
    }
  ];
  return (
    <Loader
      loading={false}
      component={() => (
        <LayoutMain className={classNames('page-courses')}>
          <div className="courses-title">
            <div className="title-content">
              <h5 className="title-label">Welcome to the Juki Couch</h5>
              <div className="description-label">
                Participate in coding contests ranging from beginner level to week-long coding marathons.
              </div>
            </div>
            <div className="image-content">
              <img src="/images/juki-person-laptop.svg" alt="judge" />
            </div>
          </div>
          <div className="courses-cards-layout">
            {courses.map((course, index) => (
              <CourseCard
                title={course.title}
                summary={course.summary}
                lessons={course.lessons}
                hours={course.hours}
                courseKey={course.key}
                key={course.key + index}
              />
            ))}
          </div>
        </LayoutMain>
      )}
    />
  
  );
};