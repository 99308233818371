import { ErrorCodes, Status } from '~/types';
import { isJson } from '~/helpers';
import { ERROR_MESSAGES } from '~/config/constants';

export const clean = ({
                        status,
                        text: responseText,
                      }: { status: number, text: string }): { status: number, success: true, data: any } | { status: number, success: false, message: string } => {
  if ((status === 200 || status === 201/*POST, Created POST*/ || status === 204/*POST, No Content*/) && isJson(responseText)) {
    return {
      status,
      success: true,
      data: JSON.parse(responseText),
    }
  }
  if (isJson(responseText)) {
    const response = JSON.parse(responseText);
    if (response.message && status === response.statusCode) {
      return {
        status,
        success: false,
        message: response.message,
      }
    }
  }
  return {
    status,
    success: false,
    message: responseText,
  }
  
};

export const clean1 = (responseText: string): any => {
  if (!isJson(responseText)) {
    // this occurs when the endpoint don't exits or server is down
    console.table({ date: new Date().toISOString(), responseText });
    return { success: Status.ERROR, message: ERROR_MESSAGES[ErrorCodes.ERR9999], errorCode: ErrorCodes.ERR9999 };
  }
  const responseJson = JSON.parse(responseText);
  if (responseJson.success) {
    if (Object.keys(responseJson).length === 3 && typeof responseJson.total === 'number' && Array.isArray(responseJson.list)) {
      return { ...responseJson, success: Status.SUCCESS };
    }
    if (Object.keys(responseJson).length === 2 && typeof responseJson.object === 'object') {
      return { ...responseJson, success: Status.SUCCESS };
    }
    console.table({ date: new Date().toISOString(), responseText });
    return { success: Status.ERROR, message: ERROR_MESSAGES[ErrorCodes.ERR9998], errorCode: ErrorCodes.ERR9998 };
  } else {
    if (Object.keys(responseJson).length === 3 && typeof responseJson.message === 'string' && typeof responseJson.errorCode === 'string') {
      return { ...responseJson, success: Status.ERROR };
    }
    return { success: Status.ERROR, message: ERROR_MESSAGES[ErrorCodes.ERR9998], errorCode: ErrorCodes.ERR9998 };
  }
};

export * from './api.user';
