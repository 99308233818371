import FileSaver from 'file-saver';

import { ErrorCodes } from '~/types';
import { ERROR_MESSAGES } from '~/config/constants';

const API = '/api/v1';
export const JUKI_COUCH_BACKEND_BASE_URL = (process.env.REACT_APP_JUKI_COUCH_BACKEND_BASE_URL || '') + API;

// USER
export const JUKI_COUCH_API = {
  ACCOUNT: {
    ME: () => {
      return `${JUKI_COUCH_BACKEND_BASE_URL}/auth/me`;
    },
    LOGOUT: () => {
      return `${JUKI_COUCH_BACKEND_BASE_URL}/auth/log-out`;
    },
    LOG_IN: () => {
      return `${JUKI_COUCH_BACKEND_BASE_URL}/auth/log-in`;
    },
    SIGNUP: () => {
      return `${JUKI_COUCH_BACKEND_BASE_URL}/auth/sign-up`;
    },
    CHANGE_PERMISSIONS: (nickname: string) => {
      return `${JUKI_COUCH_BACKEND_BASE_URL}/user/permissions/${nickname}`;
    },
    UPDATE: () => {
      return `${JUKI_COUCH_BACKEND_BASE_URL}/user/update`;
    },
    UPDATE_IMAGE: () => {
      return `${JUKI_COUCH_BACKEND_BASE_URL}/user/updateImage`;
    },
    CHANGE_PASSWORD: () => {
      return `${JUKI_COUCH_BACKEND_BASE_URL}/user/password/change`;
    },
  },
};

export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';

export const STATUS_ABORTED = 1;
export const STATUS_ERROR = 0;

export const authorizedRequest = async (url: string, method: typeof POST | typeof PUT | typeof DELETE | typeof GET = GET, body?: string, signal?: AbortSignal) => {
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set('Accept', 'application/json');
  requestHeaders.set('Content-Type', 'application/json');
  return await fetch(url, {
    method: method,
    headers: requestHeaders,
    credentials: 'include',
    ...(body ? { body } : {}),
    ...(signal ? { signal } : {}),
  })
    .then(async (response: any) => {
      return {
        status: response.status,
        text: await response.text(),
      };
    })
    .catch((error) => {
      console.warn('The Error:', error);
      if (signal?.aborted) {
        return {
          status: STATUS_ABORTED,
          text: JSON.stringify({
            statusCode: STATUS_ABORTED,
            message: ERROR_MESSAGES[ErrorCodes.ERR9997],
            error: ErrorCodes.ERR9997,
          }),
        };
      }
      return {
        status: STATUS_ERROR,
        text: JSON.stringify({
          statusCode: STATUS_ERROR,
          message: '' + error,
          error,
        }),
      };
    });
};

export const authorizedRequest1 = async (url: string, method?: typeof POST | typeof PUT | typeof DELETE | typeof GET, body?: string, signal?: AbortSignal) => {
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set('Accept', 'application/json');
  requestHeaders.set('Content-Type', 'application/json');
  return await fetch(url, {
    method: method ? method : GET,
    headers: requestHeaders,
    credentials: 'include',
    ...(body ? { body } : {}),
    ...(signal ? { signal } : {}),
  })
    .then((response: any) => {
      return response.text();
    })
    .catch((error) => {
      console.error('The Error:', error);
      if (signal?.aborted) {
        return JSON.stringify({
          success: false,
          message: ERROR_MESSAGES[ErrorCodes.ERR9997],
          errorCode: ErrorCodes.ERR9997,
        });
      }
      return 'FETCH CATCH ERROR :' + JSON.stringify({ url, error });
    });
};

export const authorizedFormDataRequest = async (url: string, method?: typeof POST | typeof PUT | typeof DELETE | typeof GET, body?: FormData, signal?: AbortSignal) => {
  return await fetch(url, {
    method: method ? method : GET,
    credentials: 'include',
    ...(body ? { body } : {}),
    ...(signal ? { signal } : {}),
  })
    .then((response: any) => {
      return response.text();
    })
    .catch((error) => {
      console.error('The Error:', error);
      return 'FETCH CATCH ERROR :' + JSON.stringify(error);
    });
};

export const downloadFile = (file: Blob, type: string, fileName: string) => {
  const blobFile = new Blob([file], { type: `text/${type}` });
  FileSaver.saveAs(blobFile, fileName);
};

export const authorizedFileRequest = async (url: string) => {
  let error = false;
  const file: any = await fetch(url, { method: GET, credentials: 'include' })
    .catch((error) => {
      error = true;
      console.error('The Error:', error);
      return 'FETCH CATCH ERROR :' + JSON.stringify(error);
    });
  if (file && !error && file.ok) {
    return await file.blob();
  }
  return null;
};

export type SearchParams = { [key: string]: Array<any> };

export interface ErrorResponse {
  status: number,
  success: false,
  message: string
}

export interface SuccessResponse<T> {
  status: number,
  success: true,
  data: T
}
