import { AdminTab, ProfileTab } from '~/types';
import { _TAB } from '~/config/constants';

export const ROUTES = {
  ROOT: '/',
  PARAMS: {
    PROFILE: 'profile',
    ADMIN: 'admin',
    COURSES: 'courses',
    COURSE: 'course',
    TASKS: 'tasks',
    TEAMS: 'teams',
    SCHEDULE: 'schedule'
  },
  COURSES: {
    LIST_PAGE: () => ['', ROUTES.PARAMS.COURSES].join('/'),
    COURSE: (courseKey: string) => ['', ROUTES.PARAMS.COURSE, courseKey].join('/')
  },
  TASKS: {
    DASHBOARD: () => ['', ROUTES.PARAMS.TASKS].join('/')
  },
  TEAMS: {
    DASHBOARD: () => ['', ROUTES.PARAMS.TEAMS].join('/')
  },
  SCHEDULE: {
    DASHBOARD: () => ['', ROUTES.PARAMS.SCHEDULE].join('/')
  },
  ABOUT: {
    PAGE: '/about'
  },
  ADMIN: {
    PAGE(tab: AdminTab | typeof _TAB) {
      return ['', ROUTES.PARAMS.ADMIN, tab].join('/');
    }
  },
  PROFILE: {
    PAGE(key: string, tab?: ProfileTab | typeof _TAB) {
      return ['', ROUTES.PARAMS.PROFILE, key, tab].join('/');
    }
  }
};
