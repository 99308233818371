import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { ReadOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { ROUTES } from '~/config/constants';

import './styles.scss';

export const CourseCard = ({ title, summary, lessons, hours, courseKey }: { title: string, summary: string, lessons: number, hours: number, courseKey: string }) => {
  
  const { t } = useTranslation();
  
  return (
    <div className="layout-course-card jk-shadow">
      <div className="image-content">
        <img src="/images/juki-person-laptop.svg" alt="judge" />
      </div>
      <div className="data-content">
        <div className="title-content">{title}</div>
        <div className="summary-content">{summary}</div>
        <div className="lessons-hours-content">
          <div className="lessons-content"><ReadOutlined /> Lessons: {lessons}</div>
          <div className="hours-content"><ClockCircleOutlined /> Hours: {hours}</div>
        </div>
        <div className="actions-content jk-child-center">
          <Link to={ROUTES.COURSES.COURSE(courseKey)}>
            <Button type="primary" className="color-secondary">
              {t('START NOW')}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
