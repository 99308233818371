import { FlagsState, UserState } from '~/types';
import { AccountStatus, Language, ProfileSettingOptions, ScopeData, Theme } from '~/types';

export const DEFAULT_PERMISSIONS: { [key in ScopeData]: { [key: string]: string } } = {
  [ScopeData.USER]: {},
  [ScopeData.PROBLEM]: {},
  [ScopeData.CONTEST]: {},
  [ScopeData.ATTEMPT]: {},
};

export const DEFAULT_SETTINGS: { [key in ProfileSettingOptions]: string } = {
  [ProfileSettingOptions.DIAGNOSTIC]: '',
  [ProfileSettingOptions.LANGUAGE]: Language.ENGLISH,
  [ProfileSettingOptions.LOW_RATE]: '',
  [ProfileSettingOptions.NOTIFICATION]: '',
  [ProfileSettingOptions.SOCIAL]: '',
  [ProfileSettingOptions.TALKS]: '',
  [ProfileSettingOptions.THEME]: Theme.LIGHT,
};

export const USER_GUEST: UserState = {
  givenName: 'Guest',
  familyName: 'Guest',
  email: 'GUEST',
  nickname: '',
  handles: {
    codeforces: '',
    topcoder: '',
    codechef: '',
    uva: '',
  },
  status: AccountStatus.ACTIVE,
  aboutMe: '',
  imageUrl: 'https://i.ibb.co/gvC4twc/juki.png',
  isLogged: false,
  permissions: { ...DEFAULT_PERMISSIONS },
  settings: { ...DEFAULT_SETTINGS },
  city: '',
  country: '',
  institution: '',
};

export const DEFAULT_FLAGS_STATE: FlagsState = {
  isLoginModalOpen: false,
  isSignUpModalOpen: false,
  isWelcomeModalOpen: false,
  requestingApiContestScoreboard: false,
  requestingApiContestMySubmissions: 0,
  requestingApiContestPendingStatus: 0,
  requestingApiContestStatus: 0,
  requestingApiProblemStatus: 0,
  requestingApiProfileSubmissions: 0,
  isSideBarOpen: true,
  lastNotification: null
};
