import { createState, useState } from '@hookstate/core';
import { DEFAULT_FLAGS_STATE } from '~/config/constants';
import { FlagsStateOptions } from '~/types';
import { useMemo } from 'react';

export const flagsState = createState({ ...DEFAULT_FLAGS_STATE });

export const useFlagsState = () => {
  const state = useState(flagsState);
  return {
    get isSignUpModalOpen() {
      return state.isSignUpModalOpen.get();
    },
    get isLoginModalOpen() {
      return state.isLoginModalOpen.get();
    },
    get isSideBarOpen() {
      return state.isSideBarOpen.get();
    },
    get lastNotification() {
      return state.lastNotification.get();
    },
  }
}

export const useFlagsDispatch = () => {
  const state = useState(flagsState);
  return useMemo(() => ({
    mergeFlags(newValue: FlagsStateOptions) {
      return () => state.merge(newValue);
    },
    // eslint-disable-next-line
  }), []);
}
