import { Dropdown, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { AppstoreIcon, Button, DownIcon, JukiCouchLogoHorImage, JukiCouchLogoVerImage, LoaderIcon, TaskIcon, TeamIcon } from '~/components';
import { DEFAULT_JUKI_PROFILE_IMAGE, ROUTES } from '~/config/constants';
import { classNames } from '~/helpers';
import { LoaderState, ProfileTab, Status } from '~/types';
import { Login } from './Login';
import { SignUp } from './SignUp';
import { useFlagsDispatch, useFlagsState, useUserDispatch, useUserState } from '~/state';

import './styles.scss';

export const InlineNavbar = () => {
  
  const { isLogged, nickname, imageUrl } = useUserState();
  const { logout } = useUserDispatch();
  const { isSignUpModalOpen, isLoginModalOpen } = useFlagsState();
  const { mergeFlags } = useFlagsDispatch();
  useEffect(() => {
    if (isLogged) {
      mergeFlags({ isSignUpModalOpen: false, isLoginModalOpen: false })();
    }
  }, [isLogged, mergeFlags]);
  const [loader, setLoader] = useState<LoaderState>([new Date().getTime(), Status.NONE]);
  const params: Array<string> = useLocation().pathname.split('/');
  const [collapsed, setCollapsed] = useState(false);
  const { t } = useTranslation();
  
  const handleMenu = async ({ key }: any) => {
    if (key === 'logout') {
      await logout(setLoader)();
    }
  };
  const keyTab = (params[1] === ROUTES.PARAMS.COURSES || params[1] === ROUTES.PARAMS.COURSE) ? ROUTES.PARAMS.COURSES : (params[1] || 'home');
  
  return (
    <>
      {isSignUpModalOpen && <SignUp />}
      {isLoginModalOpen && <Login />}
      <header className={classNames('app-side-bar', { collapsed })}>
        <div className={classNames('content-layout-side-bar', { collapsed })}>
          <div
            className={classNames('item-logo jk-child-center', { 'selected-down': ROUTES.PARAMS.COURSES === keyTab })}
            onClick={() => setCollapsed(prevState => !prevState)}
          >
            {collapsed ? <JukiCouchLogoVerImage /> : <JukiCouchLogoHorImage />}
          </div>
          <Link to={ROUTES.COURSES.LIST_PAGE()}>
            <div
              className={classNames('item-menu jk-child-center', {
                'selected-down': ROUTES.PARAMS.TASKS === keyTab,
                selected: ROUTES.PARAMS.COURSES === keyTab,
              })}
            >
              <AppstoreIcon />{t('courses')}
            </div>
          </Link>
          <Link to={ROUTES.TASKS.DASHBOARD()}>
            <div
              className={classNames('item-menu jk-child-center', {
                'selected-down': ROUTES.PARAMS.TEAMS === keyTab,
                'selected-up': ROUTES.PARAMS.COURSES === keyTab,
                selected: ROUTES.PARAMS.TASKS === keyTab,
              })}
            >
              <TaskIcon />{t('Tasks')}
            </div>
          </Link>
          <Link to={ROUTES.TEAMS.DASHBOARD()}>
            <div
              className={classNames('item-menu jk-child-center', {
                'selected-down': ROUTES.PARAMS.SCHEDULE === keyTab,
                'selected-up': ROUTES.PARAMS.TASKS === keyTab,
                selected: ROUTES.PARAMS.TEAMS === keyTab,
              })}
            >
              <TeamIcon />{t('Teams')}
            </div>
          </Link>
          {/*<Menu mode="inline" selectedKeys={[keyTab]} inlineCollapsed={collapsed}>*/}
          {/*<Menu.Item*/}
          {/*  key={ROUTES.PARAMS.SCHEDULE}*/}
          {/*  icon={<CalendarOutlined />}*/}
          {/*  className={classNames({ 'selected-bottom': ROUTES.PARAMS.TEAMS === keyTab })}*/}
          {/*>*/}
          {/*  <Link to={ROUTES.SCHEDULE.DASHBOARD()}>{t('Schedule')}</Link>*/}
          {/*</Menu.Item>*/}
          {/*</Menu>*/}
          <div className={classNames('item-user', { collapsed })}>
            {isLogged ? (loader[1] === Status.LOADING ? (
              <LoaderIcon />
            ) : (
              <div className="content-user jk-child-center logged">
                <div className="profile-image">
                  <img alt={nickname} src={imageUrl || DEFAULT_JUKI_PROFILE_IMAGE} />
                </div>
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <Menu className="layout-dropdown-body" onClick={handleMenu}>
                      {/*<Menu.Item key="rating" className="rating-label" style={{ color: 'green' }}>*/}
                      {/*  Rating (100)*/}
                      {/*</Menu.Item>*/}
                      <Menu.Item key="settings">
                        <Link
                          to={ROUTES.PROFILE.PAGE(nickname, ProfileTab.PROFILE)}
                          className="settings-label"
                        >
                          {t('my profile')}
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="logout" className="logout-label">{t('logout')} </Menu.Item>
                    </Menu>
                  }
                  placement="bottomRight"
                  arrow
                >
                  <div className={classNames('text-m-semi-bold', { 'on-my-profile': params[2] === nickname })}>
                    <div className="nickname-label">{nickname}</div>
                    <DownIcon />
                  </div>
                </Dropdown>
              </div>
            )) : (
              <div className="content-user jk-child-center">
                <Button
                  type="text"
                  className="sign-up-button"
                  onClick={mergeFlags({ isSignUpModalOpen: true })}
                  block
                >
                  {t('SIGN UP')}
                </Button>
                <Button type="primary" className="color-secondary" onClick={mergeFlags({ isLoginModalOpen: true })} block>
                  {t('LOGIN')}
                </Button>
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  );
};
