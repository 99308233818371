import React from 'react';
import { LoginInput, LoginModal } from '~/components';

import { LoaderAction } from '~/types';
import { useFlagsDispatch, useUserDispatch } from '~/state';

export const Login = () => {
  
  const { signIn } = useUserDispatch();
  const { mergeFlags } = useFlagsDispatch();
  const onSubmit = (data: LoginInput, setLoading: LoaderAction) => {
    signIn(data.nickname, data.password, setLoading)();
  };
  
  return (
    <LoginModal
      onCancel={mergeFlags({ isLoginModalOpen: false })}
      onSignUpButton={mergeFlags({ isSignUpModalOpen: true, isLoginModalOpen: false })}
      onSubmit={onSubmit}
      imageSource="https://static.oscargauss.com/images/juki-laptop.svg"
    />
  );
};
